<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <div class="share-desc">
                    <div class="activity">
                        <h2>邀请好友领电影优惠券</h2>
                        <p>活动说明：</p>
                        <ol>
                            <li>1. 邀请好友注册并首次下单成功（观影完成）后，您将获得3-10元优惠券1张</li>
                            <li>2. 优惠券有效期为3个月，多邀请多得，无上限</li>
                            <li>3. 每单（订单金额大于50元）仅限使用一张优惠券，不可叠加</li>
                        </ol>
                        <h2>分享文案：</h2>
                        <ol>
                            <li style="text-decoration: underline">{{copyText}}</li>
                        </ol>
                        <div class="fx-center">
                            <van-button id="copyBtn" round size="small" type="primary" style="margin-top: 5px;padding: 0 30px;" :data-clipboard-text="copyText">复制文案</van-button>
                        </div>
                    </div>
                </div>
                <!-- <img :src="sharePicUrl" style="width: 80%;"> -->
                <div class="share-pic-wrap">
                    <img :src="sharePicUrl" width="66%" style="border-radius: 10px;" @click="show = true">
                    <van-button v-if="!loading" round size="small" type="primary" style="margin-top: 15px;padding: 0 30px;" @click="show = true">下载图片分享好友</van-button>
                </div>
            </div>
        </div>
        <div class="utils-wrap">
            <van-overlay :show="show" @click="show = false">
                <div class="overlay-share">
                    <img :src="sharePicUrl" width="90%" style="border-radius: 10px;">
                    <div class="share-pic-tips">长按上方图片保存到相册</div>
                </div>
            </van-overlay>

            <!-- <van-overlay :show="showImgLink" @click="showImgLink = false" z-index="1009">
                <div class="imgLink-wrap">
                    <img :src="cvImg" alt="" class="cv-img">
                    <van-button style="margin-top: 30px;padding: 0 50px;" round>长按上方图片保存到相册</van-button>
                </div>
            </van-overlay> -->
        </div>
    </div>
</template>

<script>
import Clipboard from 'clipboard'
import { getShareImage, createShareImage } from '@api/permission-request'
export default {
    data() {
        return {
            loading: false,
            sharePicUrl: null,
            shareDesc: null,
            show: false,
            copyText: '全国各大电影院特惠购票，在线选座，每张电影票优惠5-30元！'
        }
    },
    created() {
        this.getShareImage();
        let copy = new Clipboard('#copyBtn')
        copy.on('success', () => {
            this.$toast('复制成功')
        });
        copy.on('error', () => {
            this.$toats('该浏览器不支持复制')
        })
    },
    methods: {
        getShareImage() {
            getShareImage().then((res) => {
                this.shareDesc = res.shareDesc
                if (res.sharePicUrl) {
                    this.sharePicUrl = res.sharePicUrl;
                    // this.show = true;
                } else {
                    this.createShareImage().then((res2) => {
                        this.sharePicUrl = res2;
                        // this.show = true;
                    });
                }

            });
        },
        createShareImage() {
            this.loading = true;
            let loading = this.$toast.loading('正在生成分享图片...');
            return new Promise((resolve, reject) => {
                createShareImage().then((res) => {
                    resolve(res);
                }).finally(() => {
                    loading.clear();
                    this.loading = false;
                })
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	position: relative;
	background-color: #fff;
	.fill-box {
		.share-desc {
			padding: 10px;
			background-color: #fff;
			line-height: 1.8;
		}

		.share-pic-wrap {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
	.activity {
		padding: 8px;
	}

	.activity h2 {
		color: #333;
		font-size: 16px;
		margin: 0;
		margin-bottom: 5px;
	}

	.activity p {
		color: #333;
		font-size: 12px;
		margin-bottom: 3px;
	}

	.activity ol {
		color: #333;
		font-size: 12px;
		margin-left: 10px;
	}

	.activity ol li {
		margin-bottom: 3px;
	}
}

.overlay-share {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
}

.share-pic-tips {
	font-size: 20px;
	color: #efefef;
	margin-top: 8px;
}
</style>
